
  import { Component, Vue } from 'vue-property-decorator'
  import { mapGetters } from 'vuex'
  import { Category, Inspection } from '@/entities/purchase'
  import { fixPrice } from '@/utils/general'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'

@Component({
  components: { RatingCell },
  methods: { fixPrice },
  computed: {
    ...mapGetters('resources/form', ['backup']),
  },
})
  export default class CategorySummary extends Vue {
  backup!: Record<string, any>
  displayConsignment = false

  async mounted () {
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: {
        table_name: { _eq: 'purchase_order' },
      },
    }))[0]

    this.displayConsignment = process?.config?.display_consignment
  }

  get inspection () {
    const { backup } = this

    return backup.inspection as Inspection
  }

  get categories (): Category[] {
    const { inspection } = this

    return inspection.metadata.categories?.filter((category: Category) => category.name !== 'Fotos')
  }
  }
